$(document).on('focus blur', '.form-control', function(e) {
  if(e.type === 'focusin'){
    $(this).parents('.form-group').addClass('focused');
  }
  if(e.type === 'focusout'){
    if(this.value.length == 0)
      $(this).parents('.form-group').toggleClass('focused');
  }
  /*console.log(e.type);
  $(this).parents('.form-group').toggleClass('focused', (e.type === 'focusout' && this.value.length == 0));
  $(this).parents('.form-group').addClass('focused', (e.type === 'focusout' && this.value.length == 0) || (e.type === 'focusin'));*/
}).trigger('blur');



